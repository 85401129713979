import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import MainButton from '../shared/main-button/MainButton';
import PropTypes from 'prop-types';
import apiService from '../../services/api.service';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone: Yup.string().required('Phone number is required')
});

const initialValues = {
  name: '',
  email: '',
  phone: ''
};

const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify({ user: value, timestamp: new Date().getTime() }));
};

const Auth = (props) => {
  let { onSuccess, pp, tnc, activeSiteId } = props;
  // const navigate = useNavigate();

  // const [saved, setSaved] = useState(false);
  // const initialValuesWithStorage = getFromLocalStorage('user') || initialValues;
  // //save the user to the collection service if it exists and navigate to collection

  // useEffect(() => {
  //   if (initialValuesWithStorage.email != '') {
  //     // navigate('/collection');
  //     onSuccess();
  //   }
  // }, [initialValuesWithStorage]);

  return (
    <div className="flex flex-col justify-between	text-center mb-3">
      {/* <div className="text-xl font-title text-rstheme">SIGN UP</div> */}
      {activeSiteId ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={async (values) => {
            const userStart = await apiService.postUser({ ...values, site: activeSiteId });
            console.log(userStart);
            saveToLocalStorage('user', userStart.data);
            onSuccess();
            //TODO: try catch for errors
          }}
        >
          {({ isValid, dirty }) => (
            <Form className="flex flex-col justify-center items-center">
              <div className="mt-5 flex flex-col justify-center items-center">
                <Field
                  className="text-black rounded-full"
                  name="email"
                  type="email"
                  placeholder="Email Address"
                />
                <ErrorMessage component="a" className="text-red-500 text-sm" name="email" />
              </div>
              <div className="mt-5 flex flex-col justify-center items-center">
                <Field
                  className="text-black rounded-full"
                  name="phone"
                  type="number"
                  placeholder="Phone Number"
                />
                <ErrorMessage component="a" className="text-red-500 text-sm" name="phone" />
              </div>
              <div className="mt-5 flex flex-col justify-center items-center">
                <Field
                  className="text-black rounded-full"
                  name="name"
                  type="text"
                  placeholder="Full Name"
                />
                <ErrorMessage component="a" className="text-red-500 text-sm" name="name" />
              </div>
              <div className="mt-4">
                <div className="mt-8 p-3">
                  <span className="text-xs">
                    By participating in this campaign you agree to be registered to our database.
                  </span>
                  <br />
                  <span className="text-xs">
                    By using this website, you agree to our{' '}
                    <a
                      className="text-blue-500 underline hover:text-blue-700 visited:text-purple-600"
                      href={pp}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      className="text-blue-500 underline hover:text-blue-700 visited:text-purple-600"
                      href={tnc}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms and Conditions
                    </a>
                    .
                  </span>
                </div>
                <MainButton
                  buttonTxt="start"
                  type="submit"
                  disabled={!isValid || !dirty}
                ></MainButton>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="p-3">
          We regret to inform you that we currently have not active at any centers.
          <br /> Please stay tuned for further updates and thank you for your understanding.
        </div>
      )}
    </div>
  );
};

Auth.propTypes = {
  onSuccess: PropTypes.func,
  pp: PropTypes.string,
  tnc: PropTypes.string,
  activeSiteId: PropTypes.string
};

export default Auth;
