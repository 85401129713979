import React from 'react';
import PropTypes from 'prop-types';

const Fact = (props) => {
  let { fact } = props;
  console.log(fact);

  return <div className="p-3">{fact.fact}</div>;
};

Fact.propTypes = {
  fact: PropTypes.object
};

export default Fact;
