import axiosInstance from './axios.service';
// import { Subject } from 'rxjs';

// const activeSiteSubject = new Subject();

// export function setActiveSiteSubject(obj) {
//   activeSiteSubject.next(obj);
// }

// export function getActiveSiteSubject() {
//   return activeSiteSubject.asObservable();
// }

class ApiService {
  //   getCategories() {
  //     return axiosInstance.get(base_url);
  //   }
  //   createCategory(category) {
  //     return axiosInstance.post(base_url, category);
  //   }
  //   getCategoryById(subCategoryId) {
  //     return axiosInstance.get(`${base_url}/${subCategoryId}`);
  //   }
  //   updateCategory(subCategoryId, subCategory) {
  //     return axiosInstance.patch(`${base_url}/${subCategoryId}`, subCategory);
  //   }

  //   deleteCategory(subCategoryId) {
  //     return axiosInstance.delete(`${base_url}/${subCategoryId}`);
  //   }

  postUnshortUrl(url) {
    return axiosInstance.post('/sites/unshort', { shortUrl: url });
  }

  getActiveSite() {
    return axiosInstance.get('/sites/active');
  }

  postUser(user) {
    return axiosInstance.post('/users/start', user);
  }

  putUser(id, collection) {
    return axiosInstance.put(`/users/collect/${id}`, collection);
  }
}

export default new ApiService();
