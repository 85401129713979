import React from 'react';
import HelpButton from '../help-button/HelpButton';
import PropTypes from 'prop-types';

const Header = (props) => {
  let { clickHelp } = props;

  return (
    <div className="flex flex-row w-full justify-center align-middle text-center mt-4">
      <div className="w-20"></div>
      <div className="flex flex-col flex-auto justify-center align-middle text-center mt-10 text-white">
        <div className="text-xl font-title">IT`S NOT</div>
        <div className="text-4xl font-title">ROCKET</div>
        <div className="text-4xl font-title">SCIENCE</div>
      </div>
      <div className="w-20">
        <HelpButton onClick={clickHelp}></HelpButton>
      </div>
    </div>
  );
};

Header.propTypes = {
  clickHelp: PropTypes.func
};

export default Header;
