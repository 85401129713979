import React, { useState } from 'react';
import { QrScanner } from '@yudiel/react-qr-scanner';
import Modal from 'react-modal';

import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import ApiService from '../../services/api.service';

const customStyles = {
  content: {
    width: '100%',
    height: '100%',
    padding: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
};

Modal.setAppElement('#root'); // Set the app element for accessibility purposes

const CollectModal = (props) => {
  let { isOpen, onRequestClose, onAfterClose, collectedNew } = props;

  const [isWaiting, setIsWaiting] = useState(false);
  const [alreadyCollected, setAlreadyCollected] = useState(false);

  const checkCollectedAndSave = (rawValue) => {
    //maybe move this to a higher level so we can collect from the initial URL scanned.
    // we could just mark it as collected straight away too, but then it wouldnt have the "you collected" part
    const value = rawValue.longUrl.split('?site=')[1];
    const collected = localStorage.getItem('collected');
    let workingCollection = [];
    console.log(collected);
    if (collected) {
      workingCollection = JSON.parse(collected);
    }

    if (!workingCollection.find((val) => val == value)) {
      workingCollection.push(value);
      localStorage.setItem('collected', JSON.stringify(workingCollection));
      collectedNew(value);
      return false;
    }
    setAlreadyCollected(true);
    return true;
  };

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      onAfterClose={onAfterClose}
    >
      <div
        className={`inset-0 absolute justify-center items-center text-center bg-scanbg overflow-hidden`}
      >
        <div className={`h-15 w-full flex flex-row justify-end flex-none bg-scanbg`}>
          <button
            className="m-5 w-10 h-10 rounded-full bg-rstheme-600 text-white active:bg-rstheme-400 hover:bg-rstheme-700"
            onClick={onRequestClose}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        <div className="flex-1 h-full w-full flex flex-col items-center text-center justify-center">
          {alreadyCollected && <p className="text-white">You have already collected this.</p>}
          {isOpen && (
            <div className="corners w-[80%] mb-20 ring-1 ring-offset-2 ring-offset-scanbg ring-white rounded-[50px]">
              <div className="theCorner topLeft">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 0,50 Q 50,0 100,50"
                    strokeLinecap="round"
                    stroke="white"
                    strokeWidth="8"
                    fill="none"
                  />
                </svg>
              </div>
              <div className="theCorner topRight">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 0,50 Q 50,0 100,50"
                    strokeLinecap="round"
                    stroke="white"
                    strokeWidth="9"
                    fill="none"
                  />
                </svg>
              </div>
              <div className="theCorner bottomLeft">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 0,50 Q 50,0 100,50"
                    strokeLinecap="round"
                    stroke="white"
                    strokeWidth="8"
                    fill="none"
                  />
                </svg>
              </div>
              <div className="theCorner bottomRight">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 0,50 Q 50,0 100,50"
                    strokeLinecap="round"
                    stroke="white"
                    strokeWidth="8"
                    fill="none"
                  />
                </svg>
              </div>
              <QrScanner
                constraints={{
                  facingMode: { exact: 'environment' }
                }}
                videoStyle={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: '50px'
                }}
                scanDelay={2000}
                hideCount
                onDecode={(result) => {
                  setAlreadyCollected(false);
                  console.log(result);
                }}
                viewFinder={() => {}}
                onResult={async (result) => {
                  console.log(result);
                  if (result) {
                    const shortUri = result.getText();
                    console.log(shortUri);
                    if (shortUri.includes('https://qrco.de/')) {
                      console.log(shortUri);
                      console.log(isWaiting);
                      if (!isWaiting) {
                        setIsWaiting(true);
                        let response = await ApiService.postUnshortUrl(shortUri);
                        checkCollectedAndSave(response.data);
                        setIsWaiting(false);
                      }
                    } else {
                      console.log('Invalid QR');
                    }
                  }
                }}
                onError={(error) => {
                  if (error) {
                    console.info(error);
                    return;
                  }
                }}
              />
            </div>
          )}
          <p className="text-white">
            Scan the QR and embark on our intergalactic adventure. Follow the prompts to locate the
            various characters around the centre.{' '}
          </p>
        </div>
      </div>
    </Modal>
  );
};

CollectModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onAfterClose: PropTypes.func,
  collectedNew: PropTypes.func
};

export default CollectModal;
