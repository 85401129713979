import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MainButton from '../shared/main-button/MainButton';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import apiService from '../../services/api.service';

const Complete = (props) => {
  const [showRedeemForm, setShowRedeemForm] = useState(false);
  const [user, setUser] = useState({});
  const [redeemed, setRedeemed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [notAllCollected, setNotAllCollected] = useState(false); // new state variable
  const [countCollected, setCountCollected] = useState(0); // new state variable

  let { site } = props;

  const validationSchema = Yup.object().shape({
    passcode: Yup.string().required('Passcode is required'),
    amount: Yup.number()
      .min(0, 'Amount can not be below 0')
      .max(10, 'Amount can not be above 10')
      .required('Amount is required')
  });

  const initialValues = {
    passcode: 'galaxy',
    amount: 0
  };

  const getUser = () => {
    let tmpUser = JSON.parse(localStorage.getItem('user'));
    console.log(tmpUser);
    if (tmpUser.user.awarded > 0) {
      setRedeemed(true);
    }
    setUser(tmpUser);
  };

  useEffect(() => {
    if (!user.user) {
      getUser();
    }

    let collectedCount = 0;

    // Check if not all spaces are collected
    if (
      site.spaces.some((space) => {
        if (space.collected) {
          collectedCount++;
        }
        return !space.collected;
      })
    ) {
      setNotAllCollected(true);
    } else {
      setNotAllCollected(false);
    }

    setCountCollected(collectedCount);
    console.log(countCollected);
  }, [user, site.spaces]);

  return (
    <div>
      {showRedeemForm ? (
        <div>
          {redeemed ? (
            <div className="text-xl p-3">
              Thank you for participating, we hope you had a blast! <br />
              <br />
              {notAllCollected
                ? 'You have redeemed your prize, but there are still more characters out there. Why not try and find the rest?'
                : "We can't wait to see you again soon."}
            </div>
          ) : (
            <div>
              Please enter the amount of Prizes you collected from Guest Services
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnMount={true}
                onSubmit={async (values) => {
                  try {
                    const userCollected = await apiService.putUser(user.user._id, {
                      awarded: values.amount,
                      passcode: values.passcode
                    });
                    console.log(userCollected);
                    localStorage.setItem(
                      'user',
                      JSON.stringify({ user: userCollected.data, timestamp: user.timestamp })
                    );
                    setRedeemed(true);
                  } catch (e) {
                    setErrorMessage(e.response.data.message || 'There was an error.');
                  }
                }}
              >
                {({ isValid, dirty }) => (
                  <Form className="flex flex-col justify-center items-center">
                    {/* <div className="mt-5 flex flex-col justify-center items-center">
                      <Field
                        className="text-black rounded-full"
                        name="passcode"
                        type="text"
                        placeholder="Enter Centre Password"
                      />
                      <ErrorMessage
                        component="a"
                        className="text-red-500 text-sm"
                        name="passcode"
                      />
                    </div> */}
                    <div className="mt-5 flex flex-col justify-center items-center">
                      <Field
                        className="text-black rounded-full"
                        name="amount"
                        type="number"
                        placeholder="Amount of Prizes"
                      />
                      <ErrorMessage component="a" className="text-red-500 text-sm" name="amount" />
                    </div>
                    <div className="mt-8 flex flex-col">
                      <div>
                        <MainButton
                          buttonTxt="Save"
                          type="submit"
                          disabled={!isValid || !dirty}
                        ></MainButton>
                      </div>
                      <div>{errorMessage}</div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      ) : (
        <div>
          {redeemed ? (
            <div className="text-xl p-3">
              Thank you for participating, we hope you had a blast! <br />
              <br />
              {notAllCollected
                ? 'You have redeemed your prize, but there are still more characters out there. Why not try and find the rest?'
                : "We can't wait to see you again soon."}
            </div>
          ) : (
            <>
              <div className="p-3">
                {/* You have found{' '}
                {countCollected == site.spaces.length ? 'all of the' : countCollected} Stations. You
                can now redeem your gift at Guest Services located at: */}
                You have found enough Stations. You can now redeem your gift (while stocks last) at
                Guest Services located at:
              </div>
              <div className="mt-3 font-bold">
                <span>{site.redeemLocation}</span>
              </div>
              <div className="mt-8">
                <MainButton
                  buttonTxt="CLAIM PRIZE"
                  onClick={() => setShowRedeemForm(true)}
                ></MainButton>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

Complete.propTypes = {
  site: PropTypes.object
};

export default Complete;
