import React from 'react';
import QRCodeImage from '../../../assets/images/QR_Code.svg';

const CameraButton = (props) => {
  let { ...buttonProps } = props;

  return (
    <button
      {...buttonProps}
      className="z-40 flex flex-row justify-center w-32 h-32 bg-white rounded-full active:bg-rstheme-500 hover:bg-rstheme-600"
    >
      <div className="flex justify-center align-middle w-20 h-20 rounded-full bg-rstheme text-white active:bg-rstheme-500 ring-4 ring-offset-4 ring-rstheme/50 self-center">
        <img className="h-14 self-center" src={QRCodeImage}></img>
      </div>
    </button>
  );
};

export default CameraButton;
