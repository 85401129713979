import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Site = (props) => {
  let { img, collected } = props;
  return (
    <div className="flex flex-col w-fit justify-center">
      <div
        className={
          collected
            ? 'overflow-hidden flex justify-center items-center w-20 h-20 bg-white drop-shadow-lg rounded-full ring-4 ring-offset-4 ring-offset-rstheme/90 ring-white'
            : 'overflow-hidden flex justify-center items-center w-20 h-20 bg-black drop-shadow-lg rounded-full ring-2 ring-offset-2 ring-offset-rstheme/90 ring-white/50'
        }
      >
        <img className="w-24 h-24 max-h-24 max-w-sm rounded-full" src={img}></img>
      </div>
      {collected && (
        <div className="absolute top-[-10px] w-6 h-6 rounded-full self-center mt-20 bg-lime-400">
          <FontAwesomeIcon icon={faCheck} size="1x" color="white" />
        </div>
      )}
    </div>
  );
};

Site.propTypes = {
  img: PropTypes.string,
  collected: PropTypes.bool
};

export default Site;
