import React from 'react';
import PropTypes from 'prop-types';

const Rules = (props) => {
  let { pp, tnc, site } = props;

  return (
    <div className="p-3">
      <div>
        Visit the various stations across the centre and collect the space characters at each
        station.
        <br /> Once you have scanned and collected the characters from at least three (3) stations,
        head to the {site.redeemLocation || 'Guest Services desk'} to show that you have collected a
        minimum of three (3) stations in order to redeem a space prize and helmet*.
      </div>
      <div className="mt-8">
        <span>
          By using this website, you agree to our{' '}
          <a
            className="text-blue-500 underline hover:text-blue-700 visited:text-purple-600"
            href={pp}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            className="text-blue-500 underline hover:text-blue-700 visited:text-purple-600"
            href={tnc}
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
          .
          <br />
          <br />
          *while stocks last
        </span>
      </div>
    </div>
  );
};
Rules.propTypes = {
  pp: PropTypes.string,
  tnc: PropTypes.string,
  site: PropTypes.object
};
export default Rules;
