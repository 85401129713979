import React, { useRef, useEffect, useState, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import CameraButton from '../shared/camera-button/CameraButton';
import Site from '../shared/site/Site';
import RegularPolygonSVG from '../shared/Polygon';
import CollectModal from '../collect/Collect';
import RSModal from '../shared/modals/RSModal';
import Auth from '../auth/Auth';
import Fact from '../fact/Fact';
import Hint from '../hint/Hint';
import Welcome from '../welcome/Welcome';
import PropTypes from 'prop-types';
import Alert from '../shared/alert/Alert';
import Complete from '../complete/Complete';

const awsS3BaseUrl = process.env.REACT_APP_AWS_S3_BUCKET;

const Collection = (props) => {
  const { activeSite, getActiveSite, collectedFromParams, setCollectedFromParams } = props;

  const graph = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  // const [gotData, setGotData] = useState(false);

  // const [welcomeSeen, setWelcomeSeen] = useState(false);

  const [collectModalIsOpen, setCollectModalIsOpen] = useState(false);
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [welcomeModalIsOpen, setWelcomeModalIsOpen] = useState(false);
  const [hintModalIsOpen, setHintModalIsOpen] = useState(false);
  const [factModalIsOpen, setFactModalIsOpen] = useState(false);
  const [completeModalIsOpen, setCompleteModalIsOpen] = useState(false);

  const [collectedAlertIsOpen, setCollectedAlertIsOpen] = useState(false);
  const [collectedContent, setCollectedContent] = useState([]);

  const [selectedSpace, setSelectedSpace] = useState({});
  const [selectedFact, setSelectedFact] = useState({});

  const [allCollected, setAllCollected] = useState(false);

  const pickRandomFact = (space) => {
    const flattenedSpaces = space.characters.flatMap((character) =>
      character.facts.map((fact) => ({
        name: character.name,
        image: character.image,
        fact: fact
      }))
    );
    console.log(flattenedSpaces);
    setSelectedFact(flattenedSpaces[Math.floor(Math.random() * flattenedSpaces.length)]);
    console.log(selectedFact);
  };

  const handleSpaceClick = (space) => {
    setSelectedSpace(space);

    if (space.collected) {
      pickRandomFact(space);
      setFactModalIsOpen(true);
    } else {
      setHintModalIsOpen(true);
    }
  };

  const checkAndGoToComplete = () => {
    if (allCollected) {
      setCompleteModalIsOpen(true);
    }
  };

  const charactersCollected = (val) => {
    if (activeSite) {
      const charactersCollected = activeSite.spaces[val - 1].characters.map((char) => char.name);
      console.log(charactersCollected);

      setCollectedAlertIsOpen(true);
      setCollectedContent(charactersCollected);
    }
  };

  const getFromLocalStorage = () => {
    const user = localStorage.getItem('user');

    if (!user) {
      setLoginModalIsOpen(true);
      return null;
    }

    // this code resets the user and colection daily
    // const { value, timestamp } = JSON.parse(user);
    // console.log(value);
    // console.log(timestamp);
    // console.log(new Date().getTime() - timestamp > 86400000);
    // if (new Date().getTime() - timestamp > 86400000) {
    //   localStorage.removeItem('user');
    //   localStorage.removeItem('collected');
    //   setLoginModalIsOpen(true);
    //   return null;
    // }

    //welcome

    // if (!welcomeSeen) {
    //   setWelcomeModalIsOpen(true); //does this only show first time they log in or does it show every time?
    //   setWelcomeSeen(true);
    // }
    // console.log(gotData);
    // if (!gotData) {
    //   //TODO: get the active centers stuff from server and load collection into memory

    //   setGotData(true);
    // }
  };

  // useEffect(() => {

  // }, []);

  useEffect(() => {
    console.log(activeSite);
    getFromLocalStorage();
    console.log(collectedFromParams);
    if (collectedFromParams) {
      console.log(collectedFromParams);
      charactersCollected(collectedFromParams);
      setCollectedFromParams(null);
    }

    let ciclegraph;
    let circleElements;

    const updateTransform = () => {
      if (circleElements) {
        let angle = 360 - 90;
        let dangle = 360 / circleElements.length;

        const containerWidth = ciclegraph.clientWidth;
        const containerHeight = ciclegraph.clientHeight;
        const radius = Math.min(containerWidth, containerHeight) / 3;

        setContainerWidth(Math.min(containerWidth, 460));

        for (let i = 0; i < circleElements.length; i++) {
          let circle = circleElements[i];
          angle += dangle;
          circle.style.transform = `rotate(${angle}deg) translate(${radius}px) rotate(-${angle}deg) translate(-${
            circleElements[i].clientWidth / 2
          }px)`;
        }
      }
    };

    if (activeSite) {
      updateTransform();
      const _allCollected = activeSite.spaces.every((space) => space.collected);
      const _collectedSpaces = activeSite.spaces.filter((space) => space.collected).length;
      const _checkCollected = _collectedSpaces >= 3;
      setAllCollected(_allCollected);
      setCompleteModalIsOpen(_checkCollected);
      console.log(_checkCollected);
      console.log(allCollected);
    }

    if (graph.current) {
      console.log(graph);

      ciclegraph = graph.current;
      circleElements = ciclegraph.childNodes;

      updateTransform();

      window.addEventListener('resize', updateTransform);
      return () => window.removeEventListener('resize', updateTransform);
    }
  }, [activeSite, collectedFromParams]);

  return (
    <div className="flex h-full flex-col justify-center align-middle text-center  overflow-hidden">
      {containerWidth && (
        <Fragment>
          <div className="absolute self-center align-self-middle translate-y-[-45px] overflow-hidden">
            <RegularPolygonSVG
              color="#8491C9"
              strokeWidth={2}
              stroke="#FFF"
              n={activeSite ? activeSite.spaces.length : 5}
              r={containerWidth / 2 - 40}
            ></RegularPolygonSVG>
          </div>
          <div className="absolute self-center align-self-middle translate-y-[-45px] overflow-hidden">
            <RegularPolygonSVG
              color="#8491C9"
              opacity={0.25}
              n={activeSite ? activeSite.spaces.length : 5}
              r={containerWidth / 2 + 40}
              // animation="animate-spin-slow"
            ></RegularPolygonSVG>
          </div>
          <div className="absolute self-center align-self-middle translate-y-[-45px] overflow-hidden">
            <RegularPolygonSVG
              color="#8491C9"
              opacity={0.15}
              n={activeSite ? activeSite.spaces.length : 5}
              r={containerWidth / 2 + 120}
              // animation="animate-spin-slow-reverse"
            ></RegularPolygonSVG>
          </div>
        </Fragment>
      )}
      <div className="absolute self-center align-self-middle translate-y-[-45px] overflow-hidden centre-logo">
        <div
          className={`flex flex-none w-36 h-36 rounded-full shadow-inner-xl bg-rstheme align-middle items-center justify-center ${
            allCollected ? 'border-4 border-lime-400' : ''
          }`}
          onClick={() => checkAndGoToComplete()}
        >
          {activeSite && (
            <img
              className="w-36 h-36 rounded-full object-cover"
              src={awsS3BaseUrl + activeSite.logo.file_slug}
            />
          )}
        </div>
      </div>

      <div
        className="flex-auto max-w-[550px] before:absolute before:top-0 before:left-0 "
        ref={graph}
      >
        {activeSite &&
          activeSite.spaces.map((space) => (
            <div
              className="absolute top-[50%] left-[50%]"
              key={space._id}
              onClick={() => handleSpaceClick(space)}
            >
              <Site collected={space.collected} img={awsS3BaseUrl + space.image.file_slug}></Site>
            </div>
          ))}
      </div>
      <div className="flex flex-none h-36 justify-center align-middle">
        {/* <Link to="/collect" className="self-center justify-self-center"> */}
        <CameraButton onClick={() => setCollectModalIsOpen(true)}></CameraButton>
        {/* </Link> */}
      </div>
      <CollectModal
        isOpen={collectModalIsOpen}
        onRequestClose={() => setCollectModalIsOpen(false)}
        collectedNew={(val) => {
          console.log(val);
          setCollectModalIsOpen(false);

          charactersCollected(val);
          // activeSite.spaces[val - 1].collected = true;
          // const charactersCollected = activeSite.spaces[val - 1].characters.map(
          //   (char) => char.name
          // );
          // console.log(charactersCollected);

          // setCollectedAlertIsOpen(true);
          // setCollectedContent(charactersCollected);
          setSelectedSpace(activeSite.spaces[val - 1]);
          getActiveSite();
        }}
      ></CollectModal>
      <RSModal
        isOpen={loginModalIsOpen}
        onRequestClose={() => setLoginModalIsOpen(false)}
        title={activeSite ? 'ENTER DETAILS' : 'NOT AVALAIBLE'}
        content={
          <Auth
            onSuccess={() => {
              setLoginModalIsOpen(false);
              setWelcomeModalIsOpen(true);
            }}
            pp={activeSite ? activeSite.privacyLink : ''}
            tnc={activeSite ? activeSite.tncLink : ''}
            activeSiteId={activeSite ? activeSite._id : ''}
          />
        }
        showCloseButton={false}
        showActionButton={false}
        onActionButtonClick={() => setLoginModalIsOpen(false)}
        shouldCloseOnOverlayClick={false}
        image={activeSite ? awsS3BaseUrl + activeSite.logo.file_slug : ''}
      ></RSModal>
      {activeSite && activeSite.sponsorLogo && welcomeModalIsOpen && (
        <div className="w-full top-[40px] absolute z-[60] flex justify-center">
          <div className="w-fit p-2.5 pb-3 rounded-lg bg-white">
            <img src={awsS3BaseUrl + activeSite.sponsorLogo.file_slug}></img>
          </div>
        </div>
      )}
      <RSModal
        isOpen={welcomeModalIsOpen}
        onRequestClose={() => setWelcomeModalIsOpen(false)}
        title="WELCOME"
        content={<Welcome />}
        showCloseButton={true}
        showActionButton={false}
        onActionButtonClick={() => setWelcomeModalIsOpen(false)}
        shouldCloseOnOverlayClick={true}
        image={activeSite ? awsS3BaseUrl + activeSite.logo.file_slug : ''}
      ></RSModal>
      <RSModal
        isOpen={hintModalIsOpen}
        onRequestClose={() => setHintModalIsOpen(false)}
        title="LOCATION"
        content={<Hint space={selectedSpace} />}
        showCloseButton={true}
        showActionButton={false}
        onActionButtonClick={() => setHintModalIsOpen(false)}
        shouldCloseOnOverlayClick={true}
        image={activeSite ? awsS3BaseUrl + activeSite.logo.file_slug : ''}
        colourFlip={false}
      ></RSModal>
      <RSModal
        isOpen={completeModalIsOpen}
        onRequestClose={() => setCompleteModalIsOpen(false)}
        title="CONGRATULATIONS"
        content={<Complete site={activeSite} />}
        showCloseButton={true}
        showActionButton={false}
        onActionButtonClick={() => setCompleteModalIsOpen(false)}
        shouldCloseOnOverlayClick={true}
        image={activeSite ? awsS3BaseUrl + activeSite.logo.file_slug : ''}
        showTick={true}
      ></RSModal>
      <RSModal
        isOpen={factModalIsOpen}
        onRequestClose={() => setFactModalIsOpen(false)}
        title={selectedFact.name}
        content={<Fact fact={selectedFact} />}
        showCloseButton={true}
        showActionButton={false}
        onActionButtonClick={() => setFactModalIsOpen(false)}
        shouldCloseOnOverlayClick={true}
        image={
          selectedFact && selectedFact.image ? awsS3BaseUrl + selectedFact.image.file_slug : ''
        }
        imageXL={true}
        colourFlip={false}
      ></RSModal>
      <Alert
        isOpen={collectedAlertIsOpen}
        onRequestClose={() => setCollectedAlertIsOpen(false)}
        title="YOU'VE COLLECTED:"
        content={
          <div className="mt-2 mb-2">
            {collectedContent.map((name, index) => (
              <div key={index}>
                <span className="uppercase text-rstheme text-xl font-title">{name}</span>
              </div>
            ))}
          </div>
        }
        buttonText="NICE"
        shouldCloseOnOverlayClick={true}
        onActionButtonClick={() => {
          setCollectedAlertIsOpen(false);
          pickRandomFact(selectedSpace);
          setFactModalIsOpen(true);
        }}
      ></Alert>
    </div>
  );
};

Collection.propTypes = {
  activeSite: PropTypes.object,
  getActiveSite: PropTypes.func,
  collectedFromParams: PropTypes.number || null,
  setCollectedFromParams: PropTypes.func
};

export default Collection;
