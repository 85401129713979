import React from 'react';
import PropTypes from 'prop-types';

const RegularPolygonSVG = ({
  n,
  r = 50,
  color = 'black',
  opacity = 1,
  stroke = 'none',
  strokeWidth = 0
  // animation = 'animate-none'
}) => {
  const points = [];
  const angle = (2 * Math.PI) / n;

  for (let i = 0; i < n; i++) {
    let x = r + r * Math.cos(i * angle);
    let y = r + r * Math.sin(i * angle);

    points.push(`${x},${y}`);
  }

  return (
    // <div className={animation}>
    <div className="animate-none">
      <svg
        width={2 * r}
        height={2 * r}
        viewBox={`-${r} -${r} ${2 * r} ${2 * r}`}
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d={`M ${points.join(' L ')} Z`}
          fill={color}
          fillOpacity={opacity}
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinejoin="bevel"
          transform={`rotate(270 ${r} ${r}) translate(${r}, ${-r})`}
        />
      </svg>
    </div>
  );
};

RegularPolygonSVG.propTypes = {
  n: PropTypes.number,
  r: PropTypes.number,
  color: PropTypes.string,
  opacity: PropTypes.number,
  strokeWidth: PropTypes.number,
  stroke: PropTypes.string
  // animation: PropTypes.string
};

export default RegularPolygonSVG;
