import React from 'react';
import PropTypes from 'prop-types';

const Hint = (props) => {
  let { space } = props;
  console.log(space);

  return (
    <div className="p-3">
      {space.locationHint.map((hint, index) => (
        <p key={index}>{hint}</p>
      ))}
    </div>
  );
};

Hint.propTypes = {
  space: PropTypes.object
};

export default Hint;
