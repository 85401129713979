import axios from 'axios';
// import config from '../config';
// import { dataService, signInWithRefresh } from './aws-service';

const base_url = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: base_url,
  headers: {
    'Content-type': 'application/json'
  }
});
// let accessToken = '';
// dataService.getAccessToken().subscribe((res) => {
//   accessToken = res;
// });

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (request) {
    // modify the config before sending the requestdataService.getAccessToken()
    // request.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';

    return request;
  },
  function (error) {
    // handle the error
    console.log(error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // modify the response data before returning it
    return response;
  },
  function (error) {
    // handle the error
    console.log(error);

    const {
      config,
      response: { status }
    } = error;
    const originalRequest = config;
    console.log(config);
    console.log(originalRequest);
    console.log(status);
    // if (status === 401) {
    //   return signInWithRefresh(localStorage.getItem('refreshToken')).then((res) => {
    //     originalRequest.headers.Authorization = `Bearer ${res.accessToken.jwtToken}`;
    //     return axiosInstance(originalRequest);
    //   });
    // }

    return Promise.reject(error);
  }
);

export default axiosInstance;
