import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import MainButton from '../main-button/MainButton';

const customStyles = {
  content: {
    width: '80%',
    height: '35%',
    padding: 0,
    maxWidth: '770px',
    minWidth: '300px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px'
  }
};

Modal.setAppElement('#root'); // Set the app element for accessibility purposes

const Alert = (props) => {
  let {
    isOpen,
    onRequestClose,
    title,
    content,
    buttonText,
    onActionButtonClick,
    shouldCloseOnOverlayClick = false
  } = props;

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className="inset-0 absolute justify-center items-center text-center bg-white">
        <div className="flex flex-col justify-between w-full flex-1">
          <div>
            <div className="text-xl font-title mt-4 mb-2 text-black">{title}</div>
            <div>{content}</div>
          </div>
          <div className="mb-5">
            <MainButton buttonTxt={buttonText} onClick={onActionButtonClick}></MainButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

Alert.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onActionButtonClick: PropTypes.func,
  onRequestClose: PropTypes.func,
  content: PropTypes.object,
  shouldCloseOnOverlayClick: PropTypes.bool
};

export default Alert;
