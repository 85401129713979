import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const HelpButton = (props) => {
  let { ...buttonProps } = props;
  return (
    <button
      className="w-10 h-10 rounded-full 
    bg-rstheme text-white active:bg-rstheme-500 hover:bg-rstheme-600 ring-2 ring-offset-2 ring-offset-rsgradtheme-light ring-rstheme/50"
      {...buttonProps}
    >
      <FontAwesomeIcon icon={faQuestion} />
    </button>
  );
};

export default HelpButton;
