import React from 'react';

const Welcome = () => {
  return (
    <div className="flex flex-row items-center justify-center mb-3">
      <iframe
        width="200px"
        height="350px"
        src="https://www.youtube.com/embed/Dh7-mZhQ4TA?rel=0&controls=0&modestbranding=1&autoplay=1&cc_lang_pref=en&cc_load_policy=1"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Welcome;
