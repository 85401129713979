import React, { useState, useEffect } from 'react';
// import Modal from 'react-modal';
import { BrowserRouter as Router, useRoutes, Navigate } from 'react-router-dom';

// import Auth from './components/auth/Auth';
import Collection from './components/collection/Collection';
// import Collect from './components/shared/modals/collect/Collect';
import Header from './components/shared/header/Header';
// import MainButton from './components/shared/main-button/MainButton';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faClose } from '@fortawesome/free-solid-svg-icons';
import RSModal from './components/shared/modals/RSModal';
import Rules from './components/rules/Rules';
import apiService from './services/api.service';

const awsS3BaseUrl = process.env.REACT_APP_AWS_S3_BUCKET;

// const customStyles = {
//   content: {
//     width: '80%',
//     height: '80%',
//     padding: 0,
//     maxWidth: '770px',
//     minWidth: '300px',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     borderRadius: '20px'
//   }
// };

const AppRoutes = (props) => {
  const { activeSite, getActiveSite, collectedFromParams, setCollectedFromParams } = props;
  const routes = useRoutes([
    // { path: '/auth', element: <Auth /> },
    {
      path: '/collection',
      element: (
        <Collection
          activeSite={activeSite}
          getActiveSite={getActiveSite}
          collectedFromParams={collectedFromParams}
          setCollectedFromParams={setCollectedFromParams}
        />
      )
    },
    // { path: '/collect', element: <Collect /> },
    { path: '/', element: <Navigate to="/collection" /> }, //we should check if we have a saved user in localstorage already and route to collection instead if we do
    { path: '/*', element: <Navigate to="/collection" /> }
  ]);
  return routes;
};

const App = () => {
  const currentUrl = window.location.href;
  console.log(currentUrl);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [apiCalled, setApiCalled] = useState(false); // state variable to track if API has been called

  const [activeSite, setActiveSite] = useState(null);
  const [collectedFromParams, setCollectedFromParams] = useState(null);

  const checkCollectedAndSave = (rawValue) => {
    const value = rawValue.split('?site=')[1];
    if (value) {
      const collected = localStorage.getItem('collected');
      let workingCollection = [];
      console.log(collected);
      if (collected) {
        workingCollection = JSON.parse(collected);
      }

      if (!workingCollection.find((val) => val == value)) {
        workingCollection.push(value);
        localStorage.setItem('collected', JSON.stringify(workingCollection));
        // collectedNew(value);
        console.log(value);
        setCollectedFromParams(value);
        return false;
      }
      // setAlreadyCollected(true);
      return true;
    }
  };
  checkCollectedAndSave(currentUrl);

  const fetchData = async () => {
    const response = await apiService.getActiveSite();
    let _activeSite = response.data;

    const savedSite = localStorage.getItem('site');
    let collected = null;

    if (savedSite !== _activeSite._id) {
      localStorage.removeItem('collected');
      localStorage.removeItem('user');
      localStorage.setItem('site', _activeSite._id);
    } else {
      collected = localStorage.getItem('collected');
    }

    console.log(collected);
    if (collected) {
      const parsedCollected = JSON.parse(collected);
      console.log(parsedCollected);
      parsedCollected.forEach((collected) => {
        console.log(collected);
        _activeSite.spaces[parseInt(collected) - 1].collected = true;
      });
      console.log(_activeSite);
    }
    // setActiveSiteSubject(_activeSite); //TODO: try catch for errors
    setActiveSite(_activeSite);
    console.log(_activeSite);
    setApiCalled(true); // set state variable to true after API call
  };

  useEffect(() => {
    if (!apiCalled) {
      fetchData();
    }

    // const subscription = getActiveSiteSubject().subscribe((value) => {
    // setActiveSite(_activeSite);
    // });
    // return () => subscription.unsubscribe();
  }, [apiCalled]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className=" overflow-hidden rs-background h-screen flex flex-col justify-center items-center after:bg-gradient-to-b after:from-rsgradtheme-light after:to-rsgradtheme-dark">
      <Header clickHelp={openModal}></Header>
      <main className="flex-auto w-full items-center overflow-hidden">
        <Router>
          <AppRoutes
            activeSite={activeSite}
            getActiveSite={fetchData}
            collectedFromParams={collectedFromParams}
            setCollectedFromParams={setCollectedFromParams}
          />
        </Router>
      </main>
      <RSModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="GAME RULES"
        content={
          <Rules
            pp={activeSite ? activeSite.privacyLink : ''}
            tnc={activeSite ? activeSite.tncLink : ''}
            site={activeSite}
          />
        }
        showCloseButton={true}
        showActionButton={false}
        onActionButtonClick={closeModal}
        colourFlip={true}
        image={activeSite ? awsS3BaseUrl + activeSite.logo.file_slug : ''}
      ></RSModal>
    </div>
  );
};

export default App;
