import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import MainButton from '../main-button/MainButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const customStyles = {
  content: {
    width: '80%',
    height: '80%',
    padding: 0,
    maxWidth: '770px',
    minWidth: '300px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px'
  }
};

Modal.setAppElement('#root'); // Set the app element for accessibility purposes

const RSModal = (props) => {
  let {
    isOpen,
    onRequestClose,
    title,
    content,
    showCloseButton = true,
    showActionButton = false,
    buttonText,
    onActionButtonClick,
    colourFlip = true,
    shouldCloseOnOverlayClick = false,
    image,
    imageXL = false,
    showTick = false
  } = props;

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className={`inset-0 absolute flex flex-col ${colourFlip ? 'bg-rstheme' : 'bg-white'}`}>
        <div className="flex flex-col flex-1 justify-center items-center text-center">
          <div
            className={`h-28 w-full flex flex-row justify-end flex-none  ${
              colourFlip ? 'bg-rstheme' : 'bg-white'
            }`}
          >
            {showCloseButton ? (
              <button
                className="m-5 w-10 h-10 rounded-full bg-rstheme-600 text-white active:bg-rstheme-400 hover:bg-rstheme-700"
                onClick={onRequestClose}
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            ) : (
              <div className="m5 w-10 h-10"></div>
            )}
          </div>
          <div className="w-full flex flex-row flex-1 justify-between text-center">
            <div
              className={`flex flex-col flex-1 bg-no-repeat bg-top  ${
                colourFlip ? 'bg-modal-white' : 'bg-modal-dark'
              }`}
            >
              <div className="flex flex-col flex-1 w-full items-center">
                <div
                  className={`flex flex-none w-36 h-36 rounded-full relative -top-16 shadow-inner-xl justify-center items-center ${
                    colourFlip ? 'bg-rstheme' : 'bg-white'
                  }`}
                >
                  {image && (
                    <img
                      className={`${imageXL ? 'w-48 h-48 max-w-[unset]' : 'rounded-full'}`}
                      src={image}
                    ></img>
                  )}
                </div>
                <div
                  className={`-mt-[35px] flex flex-col flex-1 justify-between w-full bg-gradient-to-b ${
                    colourFlip
                      ? 'from-rstheme-300/0 to-rstheme-300/90'
                      : 'from-rstheme/0 to-rstheme-700/80'
                  }`}
                >
                  <div>
                    <div className="flex flex-col items-center text-center justify-center">
                      {showTick && (
                        <div className="absolute top-0 mt-[165px] flex items-center justify-center w-12 h-12 rounded-full self-center bg-lime-400 border-white border-4">
                          <FontAwesomeIcon icon={faCheck} size="2x" color="white" />
                        </div>
                      )}
                      <div
                        className={`text-xl font-title mb-2 uppercase ${
                          colourFlip ? 'text-rstheme' : 'text-white'
                        }`}
                      >
                        {title}
                      </div>
                    </div>
                    {/* <div className={`${colourFlip ? 'text-black' : 'text-white'}`}> */}
                    <div
                      className={`${colourFlip ? 'text-black dark:text-slate-400' : 'text-white'}`}
                    >
                      {content}
                    </div>
                  </div>
                  {showActionButton && (
                    <div className="mb-5">
                      <MainButton buttonTxt={buttonText} onClick={onActionButtonClick}></MainButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

RSModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  showCloseButton: PropTypes.bool,
  showActionButton: PropTypes.bool,
  buttonText: PropTypes.string,
  onActionButtonClick: PropTypes.func,
  onRequestClose: PropTypes.func,
  content: PropTypes.object,
  colourFlip: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  image: PropTypes.string,
  imageXL: PropTypes.bool,
  showTick: PropTypes.bool
};

export default RSModal;
