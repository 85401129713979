import React from 'react';
import PropTypes from 'prop-types';

const MainButton = (props) => {
  let { buttonTxt, ...buttonProps } = props;
  return (
    <button
      className="h-10 rounded-full bg-rstheme text-white active:bg-rstheme-500 hover:bg-rstheme-600 ring-3 ring-white ring-offset-3 ring-offset-rstheme/90 self-center"
      {...buttonProps}
    >
      <span className="px-5 text-1xl font-title uppercase">{buttonTxt}</span>
    </button>
  );
};

MainButton.propTypes = {
  buttonTxt: PropTypes.string
};

export default MainButton;
